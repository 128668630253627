import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Ласкаво просимо до NewCreative Pulse
			</title>
			<meta name={"description"} content={"Де кожна повторення має значення"} />
			<meta property={"og:title"} content={"Головна | Ласкаво просимо до NewCreative Pulse"} />
			<meta property={"og:description"} content={"Де кожна повторення має значення"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/2-1.jpg?v=2024-09-23T13:07:13.313Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenu" />
		</Components.Header>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text margin="0px 0px 24px 0px" color="--dark" font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif" lg-text-align="center">
					NewCreative Pulse
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					У NewCreative Pulse ми віримо в те, щоб кожен візит до нашого тренажерного залу став особистим тріумфом. Незалежно від того, початківець ви чи досвідчений спортсмен, наше затишне середовище та найсучасніше обладнання розроблені, щоб допомогти вам досягти ваших фітнес-цілей. Пориньте в спільноту, де зустрічаються підтримка та труднощі, які допоможуть вам стати кращим.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Контакти
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-2.jpg?v=2024-09-23T13:07:13.309Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-2.jpg?v=2024-09-23T13%3A07%3A13.309Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-2.jpg?v=2024-09-23T13%3A07%3A13.309Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-2.jpg?v=2024-09-23T13%3A07%3A13.309Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-2.jpg?v=2024-09-23T13%3A07%3A13.309Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-2.jpg?v=2024-09-23T13%3A07%3A13.309Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-2.jpg?v=2024-09-23T13%3A07%3A13.309Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/1-2.jpg?v=2024-09-23T13%3A07%3A13.309Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 130px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" flex-wrap="wrap" />
			<Box width="100%" margin="0px 0px 64px 0px" md-margin="0px 0px 30px 0px">
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					width="75%"
					lg-width="100%"
					lg-text-align="center"
				>
					Чому варто вибрати NewCreative Pulse?
				</Text>
			</Box>
			<Box display="flex" width="100%" md-flex-wrap="wrap">
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					md-margin="0px 0px 40px 0px"
					margin="0px 3% 0px 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
						Дух спільноти: наш тренажерний зал – це більше, ніж просто місце для тренувань, це спільнота. Спілкуйтеся з іншими любителями фітнесу, діліться своєю подорожжю та знаходите підтримку на кожному кроці.
						<br />
						<br />
						<br />
						Індивідуальні плани тренувань: Завдяки різноманітним фітнес-програмам, створеним відповідно до ваших індивідуальних потреб, наші досвідчені тренери гарантують, що ви на правильному шляху до здоров’я.
						<br />
						<br />
						Сучасне обладнання без хитрощів: насолоджуйтесь доступом до високоякісного, простого фітнес-обладнання. Ніяких надто високих технологій, лише ефективні інструменти, які допоможуть вам досягти успіху.
					</Text>
				</Box>
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0 0px 0 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
						Гнучкі варіанти членства: вибирайте з низки планів членства, розроблених відповідно до вашого стилю життя та бюджету, без жодних прихованих комісій чи довгострокових зобов’язань.
						<br />
						<br />
						Чисте та затишне місце: наш тренажерний зал підтримується за найвищими стандартами чистоти та організації, що забезпечує комфортне та безпечне середовище для тренувань.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/2-1.jpg?v=2024-09-23T13:07:13.313Z"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
						srcSet="https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/2-1.jpg?v=2024-09-23T13%3A07%3A13.313Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/2-1.jpg?v=2024-09-23T13%3A07%3A13.313Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/2-1.jpg?v=2024-09-23T13%3A07%3A13.313Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/2-1.jpg?v=2024-09-23T13%3A07%3A13.313Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/2-1.jpg?v=2024-09-23T13%3A07%3A13.313Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/2-1.jpg?v=2024-09-23T13%3A07%3A13.313Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66f139cc9e74900024fd1b84/images/2-1.jpg?v=2024-09-23T13%3A07%3A13.313Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					NewCreative Pulse – покращення вашої форми
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Не соромтеся зв’язатися з будь-якими запитаннями або просто завітати для особистої екскурсії. Ми раді вітати вас у нашій спільноті.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});